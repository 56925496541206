<template lang="pug">
Default(:data="data")

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
<link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Noto+Sans&display=swap" rel="stylesheet">
<link href="https://unpkg.com/tabulator-tables/dist/css/tabulator.min.css" rel="stylesheet">
</template>

<script setup lang="ts">
import data from './data.json';
import setupPage from '~/scripts/stdHead';
import { useFetchPosts } from '~/public/useFetchPosts';

import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import { useRuntimeConfig } from '#app';

const config = useRuntimeConfig();
const baseURL = config.public.baseURL;
const route = useRoute();
const canonicalURL = `${baseURL}${route.fullPath}`;

useHead({
  link: [
    {
      rel: 'canonical',
      href: canonicalURL,
    },
  ],
});

onMounted(async () => {
  let currentURL = window.location.href;
  let isBlog = currentURL.endsWith('blog');
  if (isBlog) {
    const posts = await useFetchPosts();
    let accum = '';
    if (posts) {
      posts.forEach((item: { title: any; content: any }) => {
        accum += `<h2>${item.title.rendered}</h2>\n${item.content.rendered}`;
      });
    }
    const mainContentDiv = document.querySelector('.mainContent');
    if (mainContentDiv) mainContentDiv.insertAdjacentHTML('beforeend', accum);
  }
});

setupPage(data);
</script>
